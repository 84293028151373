import React from "react";

const Logo = () => (
  <svg width="82" height="101" viewBox="0 0 762 773" fill="none" xmlns="http://www.w3.org/2000/svg">
    <ellipse
      rx="348.516"
      ry="354.936"
      transform="matrix(0.995608 0.0936252 -0.0948605 0.995491 381.345 386.966)"
      fill="#BFB1FB"
    />
    <mask id="mask0" mask-type="alpha" maskUnits="userSpaceOnUse" x="32" y="31" width="698" height="711">
      <ellipse
        rx="348.516"
        ry="354.936"
        transform="matrix(0.995621 0.0934828 -0.0949982 0.995477 380.698 386.501)"
        fill="#4F565F"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        opacity="0.5"
        d="M-223.385 583.186L-321.694 793.795C-323.812 798.333 -320.624 803.555 -315.621 803.741L1017.74 853.255C1021.96 853.412 1025.36 849.825 1024.98 845.621L995.351 517.559C995.24 516.327 995.457 515.087 995.982 513.965L1044.95 409.167C1047.51 403.697 1042.41 397.783 1036.62 399.504L898.105 440.705C893.892 441.958 889.584 439.068 889.151 434.697L868.611 227.571C868.368 225.127 866.862 222.991 864.641 221.943L753.031 169.243C750.719 168.151 747.993 168.408 745.922 169.913L617.528 263.225C616.218 264.177 614.619 264.646 613.004 264.553L507.165 258.462C505.151 258.346 503.183 259.105 501.766 260.544L381.811 382.409C380.545 383.695 378.833 384.444 377.031 384.5L269.613 387.834C268.164 387.879 266.765 388.372 265.607 389.246L148.553 477.588C147.267 478.559 145.687 479.057 144.078 478.999L36.8947 475.155C35.5457 475.107 34.2109 475.45 33.051 476.142L-106.722 559.569C-107.462 560.01 -108.277 560.312 -109.126 560.458L-218.22 579.24C-220.484 579.63 -222.414 581.104 -223.385 583.186Z"
        fill="#9F8AF9"
      />
      <path
        opacity="0.5"
        d="M-32.8109 664.002L-134 745.721C-138.979 749.742 -136.412 757.774 -130.028 758.15L1243.63 839.071C1247.38 839.292 1250.65 836.508 1251.02 832.766L1308.31 256.179C1308.9 250.247 1302.28 246.35 1297.37 249.743L1204.98 313.607C1203.25 314.804 1201.07 315.16 1199.05 314.578L1099.15 285.777C1097.24 285.226 1095.18 285.514 1093.49 286.568L981.919 356.298C980.807 356.993 979.522 357.363 978.212 357.364L872.107 357.487C871.499 357.487 870.893 357.409 870.305 357.253L784.286 334.455C782.409 333.958 780.408 334.263 778.763 335.297L648.659 417.056C647.83 417.577 646.901 417.919 645.932 418.059L539.368 433.484C537.748 433.719 536.259 434.514 535.163 435.731L417.162 566.694C415.751 568.26 413.71 569.109 411.606 569.004L308.388 563.876C307.108 563.812 305.835 564.101 304.708 564.71L191.49 625.892L78.6943 691.252C77.0339 692.214 75.0524 692.454 73.2126 691.915L-26.4388 662.727C-28.6419 662.082 -31.0231 662.558 -32.8109 664.002Z"
        fill="#8369F7"
      />
    </g>
  </svg>
);

export default Logo;
